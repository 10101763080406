import * as React from 'react';
import SEO from '../components/SEO/SEO';
import Header from '../components/Header/Header';
import * as styles from './PageLayout.module.scss';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as SvgSprite from '../assets/SvgSprite';
import SectionMoreInfo from '../components/SectionMoreInfo/SectionMoreInfo';
import CustomCarousel from '../components/CustomCarousel/CustomCarousel';
import SectionMailingList from '../components/SectionMailingList/SectionMailingList';
import SectionFriends from '../components/SectionFriends/SectionFriends';
import {
  platformToIcon,
  platformToBgColor,
  platforms,
  LocationIcon,
} from '../components/V2/icons';
import useLocation from '../hooks/useLocation';

const HomeV1 = ({ pageContext }) => {
  const photosArray = pageContext.data.Photo_Gallery?.localFiles;

  return (
    <>
      <Header pageContext={pageContext} />
      <main className={styles.mainContent}>
        <section className={styles.sectionBtnExclusive}>
          {!pageContext.data.Main_Button_Link ||
          pageContext.data.Main_Button_Link?.includes('onlyfans') ? (
            <Link
              to={`/${pageContext.data.Name}/out`}
              className={styles.btnExclusiveContent}
              style={{
                backgroundColor: pageContext.data.Main_Button_Hex_Color,
              }}
            >
              {pageContext.data['Main_Button_Icon'] &&
              pageContext.data['Main_Button_Icon']?.localFiles.length ? (
                <div className={styles.btnIcon}>
                  <GatsbyImage
                    image={getImage(
                      pageContext.data['Main_Button_Icon'].localFiles[0]
                        .childImageSharp
                    )}
                    alt="Button Icon"
                  />
                </div>
              ) : (
                <SvgSprite.IconOnlyfans />
              )}
              {pageContext.data.Main_Button_Text || 'See my exclusive content'}
            </Link>
          ) : (
            <a
              href={pageContext.data.Main_Button_Link}
              className={styles.btnExclusiveContent}
              style={{
                backgroundColor: pageContext.data.Main_Button_Hex_Color,
              }}
              target="_blank"
              rel="noopener noreferrer"
            >
              {pageContext.data['Main_Button_Icon'] &&
              pageContext.data['Main_Button_Icon']?.localFiles.length ? (
                <div className={styles.btnIcon}>
                  <GatsbyImage
                    image={getImage(
                      pageContext.data['Main_Button_Icon'].localFiles[0]
                        .childImageSharp
                    )}
                    alt="Button Icon"
                  />
                </div>
              ) : (
                <SvgSprite.IconOnlyfans />
              )}
              {pageContext.data.Main_Button_Text || 'See my exclusive content'}
            </a>
          )}
          {pageContext.data['_2nd_Button'] && (
            <a
              href={pageContext.data['_2nd_Button_Link']}
              style={{
                backgroundColor: pageContext.data['_2nd_Button_Hex_Color'],
              }}
              className={styles.btnExclusiveContent}
              target="_blank"
              rel="noreferrer"
            >
              {pageContext.data['_2nd_Button_Icon'] && (
                <div className={styles.btnIcon}>
                  <GatsbyImage
                    image={getImage(
                      pageContext.data['_2nd_Button_Icon'].localFiles[0]
                        .childImageSharp
                    )}
                    alt="Button Icon"
                  />
                </div>
              )}
              {pageContext.data['_2nd_Button_Text']}
            </a>
          )}
        </section>

        <SectionMoreInfo pageContext={pageContext} />

        {pageContext.data.Photo_Gallery && (
          <section className={styles.sectionGallery}>
            <CustomCarousel
              data={photosArray}
              feedCarousel={true}
              altText={pageContext.data.Full_Name}
            />
          </section>
        )}
        {pageContext.data.Mailing_List_Code && (
          <section id="sectionForm" className={styles.sectionForm}>
            <SectionMailingList
              mailingListCode={pageContext.data.Mailing_List_Code}
            />
          </section>
        )}

        {pageContext.data.Status &&
        !!pageContext.data.Status.length &&
        pageContext.data.Status.includes('NoFriends') ? null : (
          <SectionFriends pageContext={pageContext} />
        )}

        {!pageContext.data.Hide_Footer_Logo && (
          <div className={styles.footer}>
            <div className={styles.bottomLogo}>
              <a href="https://ash.agency">
                <SvgSprite.Logo />
              </a>
            </div>

            <div className={styles.privacyPolicy}>
              <a href="/privacy">Privacy Policy</a>
            </div>
          </div>
        )}
      </main>
      {/* {pageContext.data.GTM && (
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer', '${pageContext.data.GTM}');`,
          }}
        />
      )} */}
    </>
  );
};

const WithRedNumber = ({ before, number, after }) => {
  return (
    <span>
      {before && <span>{before}</span>}
      {typeof number === 'number' && (
        <span style={{ fontWeight: 'bold', color: 'red' }}>{number} </span>
      )}
      {after && <span>{after}</span>}
    </span>
  );
};

const LinkCard = ({ platform, platformTitle, url, icon }) => {
  return (
    <a href={url} target="_blank" rel="noreferrer">
      <div
        className={styles.v2LinkCard}
        style={{ backgroundColor: platformToBgColor[platform] }}
      >
        <div className={styles.v2LinkCardIcon}>
          {platform ? (
            platformToIcon[platform]()
          ) : (
            <GatsbyImage
              image={icon}
              alt="Background image"
              className={styles.v2CustomLinkIcon}
            />
          )}
        </div>
        <p className={styles.v2LinCardTitle}>
          <WithRedNumber {...platformTitle} />
        </p>
      </div>
    </a>
  );
};

const Location = () => {
  const { country, city, isLoading } = useLocation();

  const countryName =
    country === 'United States of America (the)' ? 'USA' : country;

  return (
    <div className={styles.v2Location}>
      <LocationIcon />
      <p>{isLoading ? 'loading...' : `${city}, ${countryName}`}</p>
    </div>
  );
};

const formatIncrementedValue = (value, increment = 0) => {
  if (!value) return { before: value, number: null, after: null };

  const match = value.match(/\{\s*(?<number>\d+)\s*\}/);

  if (match) {
    const number = Number(match.groups?.number);
    if (Number.isNaN(number) === false) {
      return {
        before: value.substring(0, match.index),
        number: Math.max(number + increment, 0),
        after: value.substring(match.index + match[0].length),
      };
    }
  }

  return { before: value, number: null, after: null };
};

const formatLinks = (pageContext, increment = 0) => {
  const links = [];

  for (const platform of platforms) {
    if (Boolean(pageContext.data[platform]) === false) continue;

    links.push({
      platform: platform,
      link: pageContext.data[platform],
      platformTitle: formatIncrementedValue(
        pageContext.data[`${platform}PlatformTitle`] || '',
        increment
      ),
    });
  }

  for (const custom of pageContext.customLinks.edges) {
    const link = custom.node.data;
    if (!link['Custom_Link_Icon'] || !link['Custom_Link_Icon'].localFiles)
      continue;

    links.push({
      platform: null,
      icon: getImage(link['Custom_Link_Icon'].localFiles[0].childImageSharp),
      link: link['Custom_Link_URL'],
      platformTitle: formatIncrementedValue(
        link['Custom_Link_Text'] || '',
        increment
      ),
    });
  }

  return links;
};

const HomeV2 = ({ pageContext }) => {
  const [subheader, setSubheader] = React.useState(
    formatIncrementedValue(pageContext.data.V2_Subheader)
  );
  const [links, setLinks] = React.useState(formatLinks(pageContext));

  React.useEffect(() => {
    const timeout1 = setTimeout(() => {
      setLinks(formatLinks(pageContext, -1));
      setSubheader(formatIncrementedValue(pageContext.data.V2_Subheader, -1));
    }, 1 * 1000);
    const timeout2 = setTimeout(() => {
      setLinks(formatLinks(pageContext, -2));
      setSubheader(formatIncrementedValue(pageContext.data.V2_Subheader, -2));
    }, 3 * 1000);

    return () => {
      clearTimeout(timeout1);
      clearTimeout(timeout2);
    };
  }, []);

  return (
    <div className={styles.v2Container}>
      <div
        className={styles.v2MainCard}
        style={{
          backgroundColor: !pageContext.data.V2BackgroundImage
            ? pageContext.data.V2BackgroundColor ?? '#33aa331a'
            : 'transparent',
        }}
      >
        {pageContext.data.V2BackgroundImage && (
          <GatsbyImage
            image={getImage(
              pageContext.data.V2BackgroundImage?.localFiles[0].childImageSharp
            )}
            alt="Background image"
            className={styles.v2BackgroundImage}
          />
        )}

        <div className={styles.content}>
          <GatsbyImage
            image={getImage(
              pageContext.data.Cover_Photo_Desktop?.localFiles[0]
                .childImageSharp
            )}
            alt="Cover Photo Mobile"
            className={styles.v2ProfilePhoto}
          />

          <h1 className={styles.v2HeaderTitle}>{pageContext.data.Full_Name}</h1>
          <h2 className={styles.v2SubHeader}>
            <WithRedNumber {...subheader} />
          </h2>

          {pageContext.data.V2Location && <Location />}

          <div className={styles.V2_Links}>
            {links.map((l, i) => {
              return (
                <LinkCard
                  key={i}
                  icon={l.icon}
                  platform={l.platform}
                  platformTitle={l.platformTitle}
                  url={l.link}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export function PageLayout(props) {
  const { pageContext } = props;

  return (
    <>
      <SEO
        fullName={pageContext.data.Full_Name}
        gtm={pageContext.data.GTM}
        metaURL={pageContext.data.metaURL}
        description={pageContext.data.Description}
        OGImage={
          pageContext.data?.OGimage?.localFiles?.[0]?.childrenImageSharp?.[0]
            ?.gatsbyImageData?.images?.fallback?.src
        }
        title={pageContext.data.Title}
      />
      {pageContext.data.V2 ? (
        <HomeV2 pageContext={pageContext} />
      ) : (
        <HomeV1 pageContext={pageContext} />
      )}
    </>
  );
}
