// extracted by mini-css-extract-plugin
export var footer = "PageLayout-module--footer--6JxB7";
export var header = "PageLayout-module--header--xZM3d";
export var headerInfo = "PageLayout-module--headerInfo--JJX1k";
export var headerTitle = "PageLayout-module--headerTitle--WfMr2";
export var headerOverlay = "PageLayout-module--headerOverlay--mymjR";
export var playPauseWrapper = "PageLayout-module--playPauseWrapper--MUCRH";
export var mainContent = "PageLayout-module--mainContent--FSHCh";
export var sectionBtnExclusive = "PageLayout-module--sectionBtnExclusive--DZAd1";
export var btnExclusiveContent = "PageLayout-module--btnExclusiveContent--KvW8A";
export var btnIcon = "PageLayout-module--btnIcon--InbuG";
export var twitter = "PageLayout-module--twitter--r3Ew2";
export var sectionGallery = "PageLayout-module--sectionGallery--PNbyk";
export var sectionForm = "PageLayout-module--sectionForm--pvVyh";
export var bottomLogo = "PageLayout-module--bottomLogo--X5hY+";
export var privacyPolicy = "PageLayout-module--privacyPolicy---L6XA";
export var v2Container = "PageLayout-module--v2Container--pYSM8";
export var v2MainCard = "PageLayout-module--v2MainCard--aCg1e";
export var v2BackgroundImage = "PageLayout-module--v2BackgroundImage--z8P-R";
export var content = "PageLayout-module--content--DRayO";
export var v2ProfilePhoto = "PageLayout-module--v2ProfilePhoto--c5BSE";
export var v2HeaderTitle = "PageLayout-module--v2HeaderTitle--xeevO";
export var v2SubHeader = "PageLayout-module--v2SubHeader--WiwzH";
export var v2Location = "PageLayout-module--v2Location--pxZhm";
export var V2_Links = "PageLayout-module--V2_Links--XAZRu";
export var v2LinkCard = "PageLayout-module--v2LinkCard--ACzu+";
export var v2LinkCardIcon = "PageLayout-module--v2LinkCardIcon--v+M9Z";
export var v2LinCardTitle = "PageLayout-module--v2LinCardTitle--K5Wup";
export var v2CustomLinkIcon = "PageLayout-module--v2CustomLinkIcon--K0Xsy";